import React from "react"
import Helmet from "react-helmet"
import ContactUs from "@tightrope/footerlinks/contact-us"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function Contact() {
  return(

<HomepageLayout>
    <Helmet data={JSONData}><title>Contact Us</title></Helmet>
    <ContactUs data={JSONData}></ContactUs>
</HomepageLayout>
  )
}
